import 'dayjs/locale/pt-br'

import {
  ActionIcon,
  Badge,
  Box,
  FileInput,
  Input,
  NumberInput,
  Select,
  Switch,
  Text
} from '@mantine/core'
import { IconFile, IconPaperclip } from '@tabler/icons'
import Link from 'next/link'
import React from 'react'

import { toBase64 } from '@/lib/utils'
import { FormOption } from '@/types'

interface Props {
  config: object
  description?: string
  isRequired: boolean
  name: string
  options: FormOption[]
  type: string
  uid?: string
  value: any
  showLabel?: boolean
  onChange: (value: any) => void
  inputProps: object
}

const fieldTypes = ({
  config,
  isRequired,
  name,
  options,
  type,
  value,
  onChange,
  inputProps
}: Props) => {
  let parsedValue = value
  if (type === 'datetime' && value) {
    const datetimeValue = new Date(value)
    parsedValue = datetimeValue.setHours(datetimeValue.getHours() - 3)
  }
  switch (type) {
    case 'str':
      return (
        <Input
          type="text"
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={e => onChange(e.target.value)}
          {...inputProps}
        />
      )
    case 'int':
      return (
        <Input
          type="number"
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={e => onChange(e.target.value)}
          {...inputProps}
        />
      )
    case 'float':
      return (
        <NumberInput
          placeholder={name}
          required={isRequired}
          value={value || ''}
          decimalSeparator="."
          hideControls
          {...config}
          onChange={newValue => onChange(newValue)}
          {...inputProps}
        />
      )
    case 'bool':
      return (
        <Switch
          label={name}
          checked={value}
          required={isRequired}
          {...config}
          onChange={e => onChange(e.target.checked)}
          {...inputProps}
        />
      )
    case 'date':
      return (
        <Input
          type="date"
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={e => onChange(e.target.value)}
          {...inputProps}
        />
      )
    case 'datetime':
      return (
        <Input
          type="datetime-local"
          placeholder={name}
          required={isRequired}
          value={parsedValue ? new Date(parsedValue).toISOString().replace('Z', '') : ''}
          {...config}
          onChange={e => {
            const newValue = e.target.value
            onChange(newValue ? new Date(newValue).toISOString() : '')
          }}
          {...inputProps}
        />
      )
    case 'file':
      return (
        <Box>
          <FileInput
            icon={<IconFile />}
            // @ts-ignore
            placeholder={name}
            required={isRequired}
            value={value && typeof value !== 'string' ? value : null}
            {...config}
            onChange={async file => {
              if (file) onChange(await toBase64(file))
            }}
            {...inputProps}
          />
          {value && typeof value === 'string' && (
            <Badge
              component={Link}
              rel="noreferrer noopener"
              target="_blank"
              href={value}
              px={5}
              mt="xs"
              leftSection={
                <ActionIcon color="custom" size="xs">
                  <IconPaperclip />
                </ActionIcon>
              }
              sx={{ cursor: 'pointer' }}>
              <Text>{value.match?.(/([^/])*(?=\?AWS)/)?.[0]}</Text>
            </Badge>
          )}
        </Box>
      )
    case 'select':
      return (
        <Select
          placeholder={name}
          data={options}
          required={isRequired}
          value={value ? (typeof value === 'string' ? value : value?.[0]?.uid) : null}
          onChange={option => onChange(option)}
          {...config}
        />
      )
  }
}

export default function CustomField({
  config,
  description,
  isRequired,
  name,
  options,
  type,
  uid,
  value,
  showLabel,
  onChange,
  inputProps
}: Props) {
  return (
    <Input.Wrapper
      id={uid}
      withAsterisk={isRequired}
      label={showLabel && type !== 'bool' ? name : null}
      description={description && type !== 'bool' ? description : ''}
      inputWrapperOrder={['label', 'input', 'description', 'error']}>
      {fieldTypes({
        config,
        isRequired,
        name,
        options,
        type,
        value,
        onChange,
        inputProps
      })}
    </Input.Wrapper>
  )
}
